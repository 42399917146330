<template>
  <div class="page">
    <h2 v-if="search.brand_id" >{{brand_name}}</h2>
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button class="button" type="primary" icon="el-icon-plus" @click="handleCreate" size="small">添加</el-button>
      </div>
      <div class="pageFucView_right">
        <el-form style="display: flex" label-width="80px" label-position="left">
          <el-form-item label="商品名称:" style="margin-top: 0">
            <el-input v-model="search.name" placeholder="请输入商品名称"></el-input>
          </el-form-item>
<!--          <el-form-item label="商品状态:" style="margin-top: 0">-->
<!--            <el-select v-model="search.state" placeholder="请选择商品状态">-->
<!--              <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

          <el-button type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="resetHandle">重置</el-button>
        </el-form>
      </div>
    </div>
    <el-radio-group v-model="search.type" style="margin-bottom: 10px" @change="goodsTypeHandle">
      <el-radio-button label="all">全部<span :style="search.type == 'all'?'color:#FFF':'color: #f00'" >({{goodsSummary.all}})</span></el-radio-button>
      <el-radio-button label="listing">销售中<span :style="search.type == 'listing'?'color:#FFF':'color: #f00'">({{goodsSummary.list}})</span></el-radio-button>
      <el-radio-button label="take_down">仓库中<span :style="search.type == 'take_down'?'color:#FFF':'color: #f00'">({{goodsSummary.no_list}})</span></el-radio-button>
      <el-radio-button label="is_audit">待审核<span :style="search.type == 'is_audit'?'color:#FFF':'color: #f00'">({{goodsSummary.audit_processing}})</span></el-radio-button>
      <el-radio-button label="is_audit_fail">审核失败<span :style="search.type == 'is_audit_fail'?'color:#FFF':'color: #f00'">({{goodsSummary.audit_reject}})</span></el-radio-button>
    </el-radio-group>
    <el-table
        :data="list"
        style="width: 100%"
        border
    >
      <el-table-column
          prop="id"
          label="id"
          width="70"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="商品"
      >
        <template #default="{ row }">
          <div class="goodsInfoView">
            <el-image :src="row.pic" class="goodsPic" />
            <span class="goodsName">{{ row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="price"
          label="售价"
      width="70">
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存"
      width="70">
      </el-table-column>
      <el-table-column
          prop="sold"
          label="销量"
      width="70">
      </el-table-column>
      <el-table-column
          label="上架状态"
          prop="sales"
          width="120"
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.is_list == 0 ? '点击上架' : '点击下架'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.is_list"
                :active-value="1"
                :inactive-value="0"
                @change="changeGoodsListHandle(row.id, row.is_list)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
<!--      <el-table-column-->
<!--          label="创建/更新"-->
<!--          prop="create_at"-->
<!--          width="180"-->
<!--      >-->
<!--        <template slot-scope="scope">-->
<!--          <div>{{ scope.row.create_at | formatDate }} / {{ scope.row.update_at | formatDate }}</div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          :formatter="formatterIsCheck"
          prop="is_audit"
          label="审核"
          width="120"
      >
      </el-table-column>
      <el-table-column label="操作" width="350">
        <template #default="{ row }">
          <el-button type="text" @click="handleCloneGoods(row.id)">复制</el-button>
          <el-button type="text" @click="handleEditGoods(row.id)">编辑</el-button>
<!--          <el-button type="text" @click="">查看详情（TODO）-->
<!--          </el-button>-->
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
          <el-button type="text" @click="BrowseRecords(row.id)">浏览记录</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="search['per-page']" :current-page.sync="search.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>
<script>
import {apiAddTempGoods,apiGetGoodsList,apiDeleteGoods,apiChangeGoodsList,apiCloneGoods} from "@/request/api";
export default {
  components: {},
  props: [],
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
  data() {
    return {
      pageCount: 0,
      search: {},
      list: [],
      goodsSummary: {},
      brand_name: '',
    }
  },
  activated(){
    if(this.$route.params.brand_id ){
      this.initSearch()
      this.brand_name = this.$route.params.name
      this.getData()
    }
    if(this.$route.params.brand_id == undefined && this.search.brand_id){
      this.initSearch()
      this.getData()
    }
  },
  created() {
    this.initSearch()
    this.getData()
  },
  methods: {
    initSearch() {
      this.search = {
        name: '',
        type: 'all',
        brand_id:this.$route.params.brand_id || '',
        page: 1,
        'per-page': 10,
      }
    },
    resetHandle() {
      this.initSearch()
      this.getData()
    },
    searchHandle() {
      this.search.page = 1;
      this.search.type = 'all'
      this.getData()
    },
    goodsTypeHandle(){
      this.search.page = 1;
      this.getData()
    },
    pageChangeHandle(){
      this.getData()
      window.screenTop = 0;
    },
    getData() {
      apiGetGoodsList(this.search).then(res => {
        if(res.code == 200){
          this.list = res.data.list
          this.pageCount = res.data.count
          this.goodsSummary = res.data.summary
        }
      })
    },
    handleCreate(){
      let that = this
      apiAddTempGoods({kind:'standard'}).then(res => {
        console.log(res);
        if(res.code == 200){
          this.initSearch()
          this.getData()
          that.$router.push({path:"/goods/editGoods",query:{goodsId:res.data.id}});
        }
      })
    },
    handleEditGoods(id){
      this.$router.push({path:"/goods/editGoods",query:{goodsId:id}});
    },
    handleDelete(id){
      this.$confirm('是否删除该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDeleteGoods({ids:[id]}).then(res => {
          if(res.code == 200){
            this.getData()
          }
        })
      }).catch(() => {
      });

    },
    changeGoodsListHandle(id,is_list){
      apiChangeGoodsList({ids:[id],is_list:Number(is_list)}).then(res => {
        if(res.code == 200){
          this.getData()
        }
      })
    },
    handleCloneGoods(id){
      let that = this
      apiCloneGoods({id}).then(res => {
        if(res.code == 200){
          that.$router.push({path:"/goods/editGoods",query:{goodsId:res.data.id}});
        }
      })
    },
    formatterIsCheck: function (row) {
      if(row.is_audit == 0){
        return '待审核'
      }
      if(row.is_audit == 1){
        return '审核通过'
      }
      if(row.is_audit == 2){
        return '审核失败'
      }
    },
  }
}

</script>
<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
  /*margin-bottom: 80px;*/
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.pageFucView_right {
  display: flex;
}
.el-form-item {
  margin-bottom: 0px;
  margin-right: 10px;
}
.goodsInfoView {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
}
.goodsInfoView .goodsPic {
  width: 45px;height: 45px;border-radius: 10px;margin-right: 10px
}
.goodsInfoView .goodsName {
  max-height: 50px;
  width: calc(100% - 45px - 10px);
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
